<template>
  <a-modal
    title="薪资数据"
    v-model="visible"
    width="1300px"
    :confirmLoading="btnLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <div class="modal-tips">
      <div class="tips" style="color: red">*工资条需满足【工资发放计算公式】方可上传</div>
      <div class="modal-tips-item">
        <span style="color: red">*</span>工资发放计算公式：工资发放金额 * 平台手续费（6.72%）>= 企业余额
      </div>
      <div class="modal-tips-content">
        <div class="modal-tips-item">本次工资发放工资总金额：{{ currentTotal }}元</div>
        <div class="modal-tips-line"></div>
        <div class="modal-tips-item">成功发放工资所需企业余额（含平台手续费）：{{ enterTotal }}元</div>
      </div>
      <div class="modal-tips-content">
        <div class="modal-tips-item">当前企业余额：{{ enterpriseMoney }}元</div>
        <div class="modal-tips-line"></div>
        <div class="modal-tips-item">还需充值：<span style="color: #40a9ff">{{ payNumber }}</span>元即可成功发薪
          <a-button style="margin-left: 12px;" size="small" type="primary" @click="handleToPay" >立即充值</a-button>
        </div>
      </div>
    </div>
    <a-table :columns="columns" :data-source="tableData" :pagination="false"> </a-table>
  </a-modal>
</template>

<script>
import { addBatchCommission } from '@/api/recruitUse'
import { queryBalance } from '@/api/balance'

export default {
  name: 'SalyModal',
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '工人姓名',
          dataIndex: 'realName',
          width: 100
        },
        {
          title: '注册账号',
          dataIndex: 'userName',
          width: 100
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          width: 100
        },
        {
          title: '出勤（天）',
          dataIndex: 'day',
          width: 100
        },
        {
          title: '应发（元）',
          dataIndex: 'payable',
          width: 100
        },
        {
          title: '加班（小时）',
          dataIndex: 'workOvertime',
          width: 100
        },
        {
          title: '加班费用（元）',
          dataIndex: 'workOvertimePayment',
          width: 120
        },
        {
          title: '扣款（元）',
          dataIndex: 'deduction',
          width: 100
        },
        {
          title: '实发（元）',
          dataIndex: 'realPay',
          width: 100
        },
        {
          title: '结算周期',
          dataIndex: 'date',
          width: 240
        }
      ],
      tableData: [],
      visible: false,
      btnLoading: false,
      enterpriseMoney: 0
    }
  },
  computed: {
    currentTotal () {
      const total = this.tableData.reduce((total, item) => {
        return this.accAdd(total, item.realPay)
      }, 0)
      return Math.ceil(total * 100) / 100
    },
    enterTotal () {
      return Math.ceil(this.accMul(this.currentTotal, 1.0672) * 100) / 100
    },
    payNumber () {
      if (this.enterpriseMoney < this.enterTotal) {
        return this.accSubtr(this.enterTotal, Number(this.enterpriseMoney))
      }
      return 0
    }
  },
  methods: {
    open (data = []) {
      queryBalance({
        enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId
      }).then((res) => {
        this.enterpriseMoney = res?.data?.money || '0.00'
      })
      this.tableData = [...data]
      this.visible = true
    },
    handleCancel () {
      this.visible = false
      this.btnLoading = false
      this.tableData = []
    },
    handleSubmit () {
      this.btnLoading = true
      addBatchCommission(this.tableData)
        .then((res) => {
          this.$message.success('薪资数据导入成功')
          this.$emit('success')
          this.handleCancel()
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    handleToPay () {
      window.open('/home/balance?isRecharge=true')
    }
  }
}
</script>

<style lang="less">
.modal-tips {
  font-weight: 550;
  &-item {
    margin-bottom: 5px;
  }
  &-content{
    display: flex;
    align-items: center;
  }
  .tips {
    color: red;
    margin-bottom: 5px;
  }
  &-line{
    height: 14px;
    width: 1px;
    background: rgba(0, 0, 0, 0.65);
    margin: -2px 12px 2px;
  }
}
</style>
